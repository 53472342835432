<template>
    <div class="_wrap">
        <phoneMenu :menu="p_menu" :p_current="1"></phoneMenu>
        <div class="_auto">
            <h4></h4>

            <div class="_cont">
                <div class="_html" ref="myHtml" v-html="detail.content">

                </div>
            </div>

        </div>


        <fixIcon />
        <coment ref="comentRef"></coment>
        <mainFooter ref="mainFooterRef"></mainFooter>
    </div>
</template>

<script>
import { getDataById } from '../../api/getData';
import phoneMenu from '../../components/phoneMenu/phoneMenu.vue';

export default {
    components: {
        phoneMenu
    },
    data() {
        return {
            detail: {},
            p_menu: [
                {
                    text: "学术动态",
                    path: "/study"
                },
                {
                    text: "学术委员会",
                    path: "/studyCom"
                }, {
                    text: "书刊精选",
                    path: "/studyBook"
                },
            ]
        }
    },
    async mounted() {
        await this.getData()
        let time = 0
        let timer = setInterval(() => {
            time += 100
            this.$scallHtmlImg()
            if (time >= 3000) {
                clearInterval(timer)
            }
        }, 100);

        this.$scrollTop()
    },
    methods: {
        async getData() {
            const params = {
                cate_id: 32,
                page: 1,
                page_num: 10,
                keywords: '',
                type_id: '',
                times: '',
            }
            await getDataById(params).then(res => {
                console.log(res)
                this.detail = res.data

            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/common/mixin.scss";

@mixin tit($url)
{
    width: 299px;
    height: 52px;
    background-image: url($url);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    margin-bottom: 40px;
}

._wrap
{
    width: 100%;
    background-color: #F8F5EE;

    ._auto
    {
        width: 85%;
        margin: 0 auto;
        margin-top: 200px
    }

    h4
    {
        @include tit("../../assets/images/h4-19.png");
    }

    ._cont
    {
        width: 1620px;
        min-height: 600px;
        background: rgba(255, 255, 255, 0.4);
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #D8D0C5;

        ._html
        {
            padding: 50px;
        }
    }

}

._wrap
{
    @include respondTo('phone')
    {
        ._auto
        {
            width: 95% !important;
            padding: 0;
            margin-top: 20px;
            >h4{
                background-image: url("../../assets/images/phone/xswyh.png");
                width: 173px;
                height: 30px;
                margin-bottom: 20px;
            }

            ._cont{
                width: 100%;
                margin-bottom: 30px;
                ._html{
                    padding: 10px;
                }
            }
        }
    }
}
</style>